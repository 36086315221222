
import { editPassword } from '@/api'
import { ROUTES } from '@/router'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
    setup() {
        const store = useStore()
        const router = useRouter()
        const message = ref<string>('')
        const emitter = inject('emitter') as Emitter
        const errorMessage = ref<string>('')
        const oldPassword = ref<string>('')
        const newPassword = ref<string>('')
        const confirmPassword = ref<string>('')
        const { t } = useI18n()

        onBeforeMount(() => {
            emitter.on(EventBuss.HINT, handleHint)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.HINT, handleHint)
        })

        const handleHint = (_message: string) => {
            message.value = _message
        }

        const confirm = () => {
            // do something on change password form
            if (oldPassword.value.length === 0) {
                errorMessage.value = t('changepassword.enteroldpassword')
                return
            }

            if (newPassword.value.length === 0) {
                errorMessage.value = t('changepassword.enternewpassword')
                return
            }

            if (confirmPassword.value !== newPassword.value) {
                errorMessage.value = t('changepassword.passwordnotmatch')
                return
            }

            const _params = {
                memberId: memberid.value,
                oldPassword: oldPassword.value,
                newPassword: newPassword.value,
                newPassword2: confirmPassword.value,
                token: token.value
            }
            store.commit(AppMutation.SHOW_LOADING)
            editPassword(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 200) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('changepassword.success')
                        )
                        close()
                    } else if (response.data.status === 100) {
                        errorMessage.value = response.data.description
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.SMALL_TOAST,
                        t('prompts.networkerror')
                    )
                })
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'security'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const memberid = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])

        return {
            message,
            t,
            close,
            confirm,
            errorMessage,
            oldPassword,
            newPassword,
            confirmPassword
        }
    }
})
