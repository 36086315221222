import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1526a1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setting-msg-box-body" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_service = _resolveComponent("i-service")!
  const _component_i_rule = _resolveComponent("i-rule")!
  const _component_i_setting = _resolveComponent("i-setting")!
  const _component_i_password = _resolveComponent("i-password")!
  const _component_i_chip = _resolveComponent("i-chip")!
  const _component_i_record = _resolveComponent("i-record")!
  const _component_i_exit = _resolveComponent("i-exit")!

  return (_openBlock(), _createElementBlock("div", {
    class: "setting-msg-box-root",
    onClick: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "setting-msg-box-item",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.gotoCustumerService && _ctx.gotoCustumerService(...args)), ["prevent"]))
      }, [
        _createVNode(_component_i_service, { class: "icon" }),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('setting.customerservice')), 1)
      ]),
      _createElementVNode("div", {
        class: "setting-msg-box-item",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showRule && _ctx.showRule(...args)), ["prevent"]))
      }, [
        _createVNode(_component_i_rule, { class: "icon" }),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('setting.gamerules')), 1)
      ]),
      _createElementVNode("div", {
        class: "setting-msg-box-item",
        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showMediaSetting && _ctx.showMediaSetting(...args)), ["prevent"]))
      }, [
        _createVNode(_component_i_setting, { class: "icon" }),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('setting.gamesetting')), 1)
      ]),
      _createElementVNode("div", {
        class: "setting-msg-box-item icon-vip",
        onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showSecurity && _ctx.showSecurity(...args)), ["prevent"]))
      }, [
        _createVNode(_component_i_password, { class: "icon" }),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('setting.changepassword')), 1)
      ]),
      _createElementVNode("div", {
        class: "setting-msg-box-item icon-music",
        onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showChipsOption && _ctx.showChipsOption(...args)), ["prevent"]))
      }, [
        _createVNode(_component_i_chip, { class: "icon" }),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('setting.chipssetting')), 1)
      ]),
      _createElementVNode("div", {
        class: "setting-msg-box-item icon-video",
        onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.emitDialogEvent('record')), ["prevent"]))
      }, [
        _createVNode(_component_i_record, { class: "icon" }),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('setting.betrecord')), 1)
      ]),
      _createElementVNode("div", {
        class: "setting-msg-box-item icon-quit",
        onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.logOut && _ctx.logOut(...args)), ["prevent"]))
      }, [
        _createVNode(_component_i_exit, { class: "icon" }),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('setting.exitgame')), 1)
      ])
    ])
  ]))
}