import { createI18n } from 'vue-i18n'
import Cookies from 'js-cookie'
import { CookiesToken } from '@/types/CookiesToken'
import { getBrowserLanguage, getCookieValue } from '.'
import gamerules from './rules'

const messages = {
    en: {
        serverselection: {
            lineSelection: 'Line Selection',
            p1: 'Line is green',
            p2: 'You can click directly to enter. Red',
            p3: 'Indicates that the line is unavailable,',
            p4: 'If all three lines are unavailable, please contact online customer service to obtain more lines, enter the obtained line address and click to enter the game. I wish you a happy game.',
            line1: 'Line1',
            line2: 'Line2',
            line3: 'Line3',
            customline: 'Custom Lines',
            enter: 'Enter',
            lineerror: 'IP is invalid'
        },
        login: {
            username: 'Enter your username',
            password: 'Enter your password',
            commandmode: 'Command mode',
            phonemode: 'Phone mode',
            enterphonenumber: 'Please enter your phone number',
            verificationcode: 'Enter verification Code',
            useragreement: 'User Agreement',
            login: 'Login',
            guestlogin: 'Login as guest',
            usernameerror: 'Please enter username',
            passworderror: 'Please enter password',
            phoneerror: 'Phone number is required',
            codeerror: 'Invalid Verification code',
            demologin: {
                title: 'Verification Code',
                codeerror: 'Please enter varification code'
            }
        },
        common: {
            onlineservice: 'Customer Service',
            networkerror: 'Network error',
            confirm: 'Confirm',
            cancel: 'Cancel',
            pager: '{0} items per page, total of {1} items',
            goto: 'Go to Page',
            baccarat: 'Baccarat',
            dragontiger: 'Dragon Tiger',
            hint: 'Hint'
        },
        dialogs: {
            useragreement: {
                title: 'User Agreement',
                content:
                    'Dear guest, welcome to Kakawan Casino, you are about to use Kakawan Casino instructions When betting, the company will follow the instructions you send. All wins and losses after the cards are opened belong to you. Website The route is for reference only, everything will be subject to the on-site game results. If you agree, please click Confirm below If you have any objections, please close the web page and contact the accountant. Thank you!'
            },
            reservetabletext: 'Are you sure you want to reserve a table?'
        },
        roadmap: {
            banker: 'B',
            player: 'P',
            tie: 'T',
            tiger: 'T',
            dragon: 'D',
            pair: 'Pr.',
            ask: '{0}.ASK'
        },
        loby: {
            baccarattable: 'BAC.',
            dragontigertable: 'DT.',
            universal: 'Universal',
            phonemode: 'Phone',
            commandmode: 'Command',
            atthistable: 'You are at this table',
            all: 'All',
            baccarat: 'BAC.',
            dragontiger: 'DT.',
            multiple: 'Multiple',
            tip: 'TIP',
            menu: 'MENU',
            startmultibetting: 'Start Multi-station betting',
            selectupto: 'Select up to 4 tables',
            fourtablesonly: 'Sorry. You can only select 4 tables.',
            alllimit: 'All',
            allgames: 'All Games',
            fastbaccarat: 'Fast BAC.',
            firstdealbaccarat: 'First Deal BAC.',
            traditionalbaccarat: 'Traditional BAC.',
            traditionaldragontiger: 'Traditional DT.',
            firstdealdragontiger: 'First Deal DT.',
            fastdragontiger: 'Fast DT.'
        },
        prompts: {
            networkerror:
                'There is a problem with your network, please try again.',
            bindtogunner:
                'You are not bound to a gunner yet, Please bind first.',
            iscommandtableselectother:
                ' is a command table, please choose other table games!',
            isphonetableselectother:
                ' is phone mode betting table, please choose other table games! ',
            tableisreserved:
                'This table is reserved, please choose other table to place your bets',
            sessionexpired: 'You have been logged out due to inactivity',
            betting: {
                gunnerbetmode:
                    'The current bet is placed by the Gunner, please switch modes',
                nobetallowed: 'Betting currently not available',
                openisconfirmednomorebets:
                    'The opening has been confirmed and no more bets can be made',
                betsuccess: 'Successful BET'
            },
            nobettimeoutidle:
                'You have not placed any bets for a long time, please log in again',
            switchtoselfbettingmode:
                'You have switched to the self-betting mode',
            switchgunerbettingmode: 'Switched to Gunners betting mode',
            pausegamerequestsetnt:
                'You have sent a pause instruction and the operator has received your instruction information!',
            tablecloseforaccess:
                'The current table is closed for access, please choose another table for betting.',
            socketnetworkerror:
                'Network error, please log in again later, thank you!'
        },
        setting: {
            customerservice: 'Customer service',
            gamerules: 'Game rules',
            gamesetting: 'Game setting',
            changepassword: 'Change password',
            chipssetting: 'Chips Setting',
            betrecord: 'Bet history',
            exitgame: 'Logout'
        },
        changepassword: {
            oldpassword: 'Old Password',
            newpassword: 'New Password',
            confirmpassword: 'Confrim Password',
            enteroldpassword: 'Please enter old password',
            enternewpassword: 'Please enter new password',
            passwordnotmatch: 'Password not match',
            success: 'Password has been modified.',
            changepasswordhint:
                'For the security of your account, it is recommended that you reset your password when you log in for the first time. The password must be at least 6 characters long and must be a combination of letters and numbers.'
        },
        gamesetting: {
            video: 'Video',
            sound: 'Sound'
        },
        bethistory: {
            totalbet: 'Total BET',
            totalvalidbets: 'Total Valid BET',
            totalwinorloss: 'Total Win/Loss',
            table: 'Table',
            gameid: 'Game #',
            gametype: 'Game Type',
            bettype: 'Bet Type',
            gameresult: 'Result',
            card: 'Cards',
            winorloss: 'Win/Loss',
            date: 'Date'
        },
        game: {
            settling: 'SETTLING',
            shoeround: 'SHOE',
            one: 'One',
            total: 'Total',
            changeshoe: 'C.SHOE',
            freegame: 'FREE',
            tip: 'TIP',
            pause: 'Pause',
            opencard: 'OPEN',
            menu: 'MENU',
            switchtable: 'TABLES',
            g2limit: 'BANKER/Player Limit:',
            g1limit: 'DRAGON/TIGER Limit:',
            tielimit: 'TIE LIMIT:',
            pairlimit: 'PAIR LIMIT:',
            customchip: '#??',
            playerpair: 'P.PAIR',
            tie: 'TIE',
            bankerpair: 'B.PAIR',
            player: 'PLAYER',
            banker: 'BANKER',
            dragon: 'DRAGON',
            tiger: 'TIGER',
            confirmbet: 'CONFIRM',
            cancelbet: 'CANCEL',
            gameid: 'GAME ID',
            close: 'CLOSE'
        },
        rules: {
            baccarat: gamerules.en.baccarat,
            dragontiger: gamerules.en.dragontiger
        },
        multibetting: {
            redlimit: 'Min-Max',
            remove: 'X',
            enter: 'GO',
            shoe: 'Shoe',
            juno: 'Bureau'
        }
    },
    'zh-cn': {
        serverselection: {
            lineSelection: '线路选择',
            p1: '线路为绿色',
            p2: '可直接点击进入，红色',
            p3: '代表该线路不可用，',
            p4: '如三个线路均不可用，请联系在线客服获取更多线路，并输入所获取的线路地址点击进入，即可游戏，祝您游戏愉快。',
            line1: '线路一',
            line2: '线路二',
            line3: '线路三',
            customline: '自定义线路',
            enter: '进入',
            lineerror: 'ip 地址错误'
        },
        login: {
            username: '请输入您的用户名',
            password: '输入您的密码',
            commandmode: '指令',
            phonemode: '电投',
            enterphonenumber: '请输入电话号码',
            verificationcode: '请输入验证码',
            useragreement: '同意用户协议',
            login: '登 入',
            guestlogin: '游客登入',
            usernameerror: '请输入用户名',
            passworderror: '请输入密码',
            phoneerror: '电话号码为必填项',
            codeerror: '无效验证码',
            demologin: {
                title: '验证码',
                codeerror: '请先输入验证码'
            }
        },
        common: {
            onlineservice: '在线客服',
            networkerror: '网络错误',
            confirm: '确定',
            cancel: '取消',
            pager: '每页{0}条, 共 {1} 条',
            goto: '前往',
            baccarat: '百家乐',
            dragontiger: '龙虎',
            hint: '提示'
        },
        dialogs: {
            useragreement: {
                title: '用户协议',
                content:
                    '尊敬的贵宾，欢迎光临卡卡湾娱乐场，您即将使用卡卡湾娱乐场指令 投注，公司将以您发送的指令为准，开牌后一切输赢归您所有，网站 路子仅供参考，一切以现场游戏结果为准。若您同意，请点击下方确 定按钮，若您有异议，请您关闭网页，联系账房，谢谢！'
            },
            reservetabletext: '确定要包桌吗？'
        },
        roadmap: {
            banker: '庄',
            player: '闲',
            tie: '和',
            tiger: '虎',
            dragon: '龙',
            pair: '对',
            ask: '{0}问路'
        },
        loby: {
            baccarattable: '百家乐',
            dragontigertable: '龙虎',
            universal: '通用台',
            phonemode: '电投台',
            commandmode: '指令台',
            atthistable: '您在此桌',
            all: '全部',
            baccarat: ' 百家乐',
            dragontiger: ' 龙虎',
            multiple: '多台',
            tip: '小费',
            menu: '功能',
            startmultibetting: ' 开始多台投注',
            selectupto: '选择最多4张台进行投注',
            fourtablesonly: '抱歉，您只能选择 4 个表',
            alllimit: '全部限红',
            allgames: '全部游戏',
            fastbaccarat: '极速百家乐',
            firstdealbaccarat: '先发牌百家乐',
            traditionalbaccarat: '传统百家乐',
            traditionaldragontiger: '传统龙虎',
            firstdealdragontiger: '先发牌龙虎',
            fastdragontiger: '极速龙虎'
        },
        prompts: {
            networkerror: '当前网络不佳，请重新尝试',
            bindtogunner: '您未绑定枪手，请先绑定枪手再进入台桌',
            iscommandtableselectother: '号台桌为指令台,请选择其他台桌游戏！',
            isphonetableselectother: '号台桌为电投台,请选择其他台桌游戏！',
            tableisreserved: '该台桌已被包桌，请选择其他台桌进行投注',
            sessionexpired: '您因不活动而被注销',
            betting: {
                gunnerbetmode: '当前由枪手下注，请切换模式',
                nobetallowed: '当前不能下注',
                openisconfirmednomorebets: '已确认开牌，无法再投注',
                betsuccess: '下注成功'
            },
            nobettimeoutidle: '您长时间没有投注 , 请重新登录',
            switchtoselfbettingmode: '您已切换至自主投注模式',
            switchgunerbettingmode: '已切换至枪手投注模式',
            pausegamerequestsetnt:
                '您已发送暂停指令，接线员已收到您的指令信息！',
            tablecloseforaccess: '当前台桌已关闭访问，请选择其他台桌进行投注',
            socketnetworkerror: '网络错误，请您稍后重新登录系统，谢谢！'
        },
        setting: {
            customerservice: '在线客服',
            gamerules: '游戏规则',
            gamesetting: '游戏设置',
            changepassword: '修改密码',
            chipssetting: '筹码设置',
            betrecord: '投注记录',
            exitgame: '登出游戏'
        },
        changepassword: {
            oldpassword: '旧密码',
            newpassword: '新密码',
            confirmpassword: '确认密码',
            enteroldpassword: '请输入旧密码',
            enternewpassword: '请输入新密码',
            passwordnotmatch: '密码不匹配',
            success: '修改成功',
            changepasswordhint:
                '为了您的账号安全，建议您初次登录时重设密码，密码不得少于6位，且需字母和数字的组合.'
        },
        gamesetting: {
            video: '视频开关',
            sound: '声音开关'
        },
        bethistory: {
            totalbet: '总投注',
            totalvalidbets: '总有效投注',
            totalwinorloss: '总输赢',
            table: '桌号',
            gameid: '游戏编号',
            gametype: '游戏类型',
            bettype: '投注类型',
            gameresult: '游戏结果',
            card: '牌型',
            winorloss: '输赢',
            date: '日期'
        },
        game: {
            settling: '结算中',
            shoeround: '靴局',
            one: '单',
            total: '总',
            changeshoe: '换靴',
            freegame: '飞牌',
            tip: '小费',
            pause: '暂停',
            opencard: '开牌',
            menu: '功能',
            switchtable: '切换桌台',
            g2limit: '庄闲 限红:',
            g1limit: '龙虎 限红:',
            tielimit: '和 限红:',
            pairlimit: '对子 限红:',
            customchip: '自定义',
            playerpair: '闲对',
            tie: '和',
            bankerpair: '庄对',
            player: '闲',
            banker: '庄',
            dragon: '龙',
            tiger: '虎',
            confirmbet: '确认投注',
            cancelbet: '取消投注',
            gameid: '게임 ID',
            close: '关闭'
        },
        rules: {
            baccarat: gamerules['zh-cn'].baccarat,
            dragrontiger: gamerules['zh-cn'].dragontiger
        },
        multibetting: {
            redlimit: '限红',
            remove: '移除',
            enter: '进入',
            shoe: '靴',
            juno: '局'
        }
    }
}

const lang = getCookieValue(CookiesToken.LANG) ?? getBrowserLanguage()

const i18n = createI18n({
    legacy: false,
    locale: lang,
    fallbackLocale: 'zh-cn',
    globalInjection: true,
    warnHtmlInMessage: false,
    warnHtmlMessage: false,
    messages
})

export default i18n
