
import { useStore } from '@/store'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    LoginData,
    onMounted,
    ref,
    ResponseData,
    UserInfo
} from 'vue'
import { ISheildCheck, IReload } from '@/components/icons'
import { useCapchaImage } from '@/composables/useCapchaImage'
import { AppMutation } from '@/store/types'
import { demoLogin } from '@/api'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    components: {
        ISheildCheck,
        IReload
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()

        const { getImage, isLoadingCapcha, capchaSrc, headerToken } =
            useCapchaImage()

        const isMobile = computed((): boolean => store.getters['isMobile'])

        const capchaInput = ref<string>('')

        getImage()

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'demo'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            if (capchaInput.value.length === 0) {
                emitter.emit(
                    EventBuss.TOAST_MESSAGE,
                    t('login.demologin.codeerror')
                )
                return
            }

            const _params = {
                verifyCode: capchaInput.value,
                header: { 'Access-Token': headerToken.value }
            }
            store.commit(AppMutation.SHOW_LOADING)
            demoLogin(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    const responseData =
                        response.data as ResponseData<LoginData>

                    if (responseData.status === 200) {
                        const { token, amount, code, id, commandType } =
                            responseData.data!.userInfo

                        const userSession = {
                            token,
                            username: code,
                            notice: '',
                            point: amount,
                            memberId: id,
                            isFree: true,
                            commandType
                        }

                        const _userInfo: UserInfo = responseData.data!.userInfo

                        _userInfo.firstLogin = 1

                        store.commit(AppMutation.INIT_USER_SESSION, userSession)
                        store.commit(
                            AppMutation.SET_USER_INFO,
                            responseData.data?.userInfo
                        )
                        emitter.emit(EventBuss.SOCKET_CONNECT)
                        emitter.emit(EventBuss.START_CLIENT_TIME_OUT)
                        close()
                        router.push({ name: ROUTES.LOBY })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )
                    }
                })
                .catch((e) => {
                    console.log(e)
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('common.networkerror')
                    )
                })
        }

        const cancel = () => {
            close()
        }

        const onCapchaKeydown = (event: KeyboardEvent) => {
            if (event.key.toLowerCase() !== 'enter') {
                if (event.key.toLowerCase() === 'e') {
                    event.preventDefault()
                    return
                }

                if (
                    document.getSelection()?.toString() ===
                    capchaInput.value.toString()
                ) {
                    return
                }

                if (
                    capchaInput.value.toString().length > 3 &&
                    event.key.toLowerCase() !== 'backspace'
                ) {
                    event.preventDefault()
                    return
                }
            }

            if (event.key.toLowerCase() === 'enter') {
                confirm()
            }
        }

        const activeLang = computed((): string => store.getters['lang'])

        onMounted(() => {
            //
        })

        return {
            isMobile,
            activeLang,
            capchaInput,
            capchaSrc,
            isLoadingCapcha,
            t,
            getImage,
            confirm,
            close,
            cancel,
            onCapchaKeydown
        }
    }
})
