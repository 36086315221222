
import { defineComponent, PropType, ref } from 'vue'
import { IArrowL } from '@/components/icons'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['goto-page'],
    props: {
        pageSize: { type: Number as PropType<number>, default: 10 },
        totalSize: { type: Number as PropType<number>, default: 0 },
        totalPage: { type: Number as PropType<number>, default: 0 },
        pageNo: { type: Number as PropType<number>, default: 1 }
    },
    components: {
        IArrowL
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const gotoPage = (_page: number) => {
            emit('goto-page', _page)
        }

        const prev = () => {
            const _toP = props.pageNo - 1
            if (_toP > 0) {
                gotoPage(_toP)
            }
        }

        const next = () => {
            const _toP = props.pageNo + 1
            if (_toP <= props.totalPage) {
                gotoPage(_toP)
            }
        }

        return { t, gotoPage, prev, next }
    }
})
