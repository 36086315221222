import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76eb7af2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination-root" }
const _hoisted_2 = { class: "pagination-wrap" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_arrow_l = _resolveComponent("i-arrow-l")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.t('common.pager', [_ctx.pageSize, _ctx.totalSize])), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_i_arrow_l, {
        class: "arrow-left",
        onClick: _withModifiers(_ctx.prev, ["prevent"])
      }, null, 8, ["onClick"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPage, (i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["btn-page", { active: i === _ctx.pageNo }]),
          key: i,
          onClick: _withModifiers(($event: any) => (_ctx.gotoPage(i)), ["prevent"])
        }, _toDisplayString(i), 11, _hoisted_3))
      }), 128)),
      _createVNode(_component_i_arrow_l, {
        class: "arrow-right",
        onClick: _withModifiers(_ctx.next, ["prevent"])
      }, null, 8, ["onClick"])
    ]),
    _createTextVNode(" " + _toDisplayString(_ctx.t('common.goto')) + " ", 1),
    _createElementVNode("input", {
      maxlength: "2",
      type: "number",
      value: _ctx.pageNo
    }, null, 8, _hoisted_4)
  ]))
}