
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    BetRecord,
    BetRecordData,
    computed,
    defineComponent,
    Emitter,
    inject,
    onMounted,
    ref,
    ResponseData,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import RangeCalendarPicker from '@/components/range-calendar-picker.vue'
import { getBetRecordList } from '@/api'
import Pagination from '@/components/pagination.vue'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'

export default defineComponent({
    components: {
        // 'dt-calendar-picker': RangeCalendarPicker,
        'mj-pgnation': Pagination
    },
    setup() {
        const { t } = useI18n()
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const dateValue = ref<{ start: Date; end: Date }>()
        const isMobile = computed((): boolean => store.getters['isMobile'])
        const router = useRouter()
        const cardFaces = [
            '<div class="black">&#9824;</div>',
            '<div class="red">&#9829;</div>',
            '<div class="black">&#9827;</div>',
            '<div class="black">&#9830;</div>'
        ]

        const dataInfo = ref<BetRecordData>()

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'record'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            emitter.emit(EventBuss.AGREE_TO_AGREEMENT, true)
            close()
        }

        const cancel = () => {
            emitter.emit(EventBuss.AGREE_TO_AGREEMENT, false)
            close()
        }

        const fetchData = (_params: any) => {
            console.log(_params)
            store.commit(AppMutation.SHOW_LOADING)
            getBetRecordList(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    const recordData =
                        response.data as ResponseData<BetRecordData>
                    if (recordData && recordData.status === 200) {
                        dataInfo.value = recordData.data!
                    } else if (recordData && recordData.status === 301) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            recordData.description
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            recordData.description
                        )
                    }
                })
                .catch((e) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompts.networkerror')
                    )
                })
        }

        const fetchDataToaPeriodOfTime = () => {
            if (dateValue.value) {
                const { start, end } = dateValue.value
                console.log(dateValue.value)

                const _params = {
                    memberId: memberId.value,
                    token: token.value,
                    timestart: start.getTime(),
                    timeend: end.getTime()
                }
                fetchData(_params)
            }
        }

        const toPage = (_page: number) => {
            if (dateValue.value) {
                const { start, end } = dateValue.value

                const _params = {
                    memberId: memberId.value,
                    token: token.value,
                    timestart: start.getTime(),
                    timeend: end.getTime(),
                    page: _page
                }
                fetchData(_params)
            } else {
                const _params = {
                    memberId: memberId.value,
                    token: token.value,
                    page: _page
                }
                fetchData(_params)
            }
        }

        onMounted(() => {
            // initialize date
            const b = {
                start: new Date(),
                end: new Date()
            }

            b.start.setHours(6)
            b.start.setMinutes(0)
            b.start.setSeconds(0)
            b.end.setDate(b.end.getDate() + 1)
            b.end.setHours(5)
            b.end.setMinutes(59)
            b.end.setSeconds(59)
            dateValue.value = b
            const _params = { memberId: memberId.value, token: token.value }
            fetchData(_params)
        })

        const getCardValue = (_code: string) => {
            if (_code) {
                const _pval = parseInt(_code.substr(1, 2))
                if (_pval <= 10) return _pval
                else if (_pval === 11) return 'J'
                else if (_pval === 12) return 'Q'
                else if (_pval === 13) return 'K'
                return _pval
            }

            return ''
        }

        const memberId = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])

        const hasData = computed(() => {
            if (dataInfo.value)
                return dataInfo.value.betRecordList.itemsCount > 0
            return false
        })

        const totalBetValidAmount = computed(() => {
            if (dataInfo.value) return dataInfo.value.totalBetValidAmount
            return 0
        })
        const totalBetAmount = computed(() => {
            if (dataInfo.value) return dataInfo.value.totalBetAmount
            return 0
        })

        const totalWinResult = computed(() => {
            if (dataInfo.value) return dataInfo.value.totalWinResult
            return 0
        })

        const pageSize = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.pageSize
            return 0
        })

        const totalSize = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.totalSize
            return 0
        })

        const totalPage = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.totalPage
            return 0
        })

        const pageNo = computed(() => {
            if (dataInfo.value) return dataInfo.value.betRecordList.pageNo
            return 0
        })

        const dataInfoList = computed((): BetRecord[] => {
            if (dataInfo.value) return dataInfo.value.betRecordList.items
            return []
        })

        const lang = computed(() => store.getters['lang'])

        return {
            isMobile,
            dateValue,
            hasData,
            totalBetValidAmount,
            totalWinResult,
            totalBetAmount,
            pageSize,
            totalSize,
            totalPage,
            pageNo,
            dataInfoList,
            cardFaces,
            lang,
            t,
            toPage,
            getCardValue,
            fetchDataToaPeriodOfTime,
            fetchData,
            confirm,
            close,
            cancel
        }
    }
})
