import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2754092c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "roadmap-root" }
const _hoisted_2 = { ref: "mapCanvas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _hoisted_2, null, 512)
  ]))
}