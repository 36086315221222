import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ee00a02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ctrl-chips-root" }
const _hoisted_2 = { class: "chips-list-control" }
const _hoisted_3 = { class: "chips-list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "chip-is-selected"
}
const _hoisted_6 = { class: "custom-chip-wrap" }
const _hoisted_7 = {
  key: 0,
  class: "chip-is-selected"
}
const _hoisted_8 = { class: "custom-chip-content" }
const _hoisted_9 = {
  key: 1,
  class: "label"
}
const _hoisted_10 = {
  key: 2,
  class: "value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow_g = _resolveComponent("icon-arrow-g")!
  const _component_ctrl_btn_click_db_click = _resolveComponent("ctrl-btn-click-db-click")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_icon_arrow_g, {
        class: "icon-left",
        onClick: _withModifiers(_ctx.moveBack, ["prevent"])
      }, null, 8, ["onClick"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chips.value, (chip, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["chips-list-item", index === 0 ? `pg${_ctx.scrollNo}` : '']),
            key: index,
            onClick: _withModifiers(($event: any) => (_ctx.selectThisChip(index)), ["prevent"])
          }, [
            (_ctx.currentChip === index)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["chip", `chip-${index}`])
            }, _toDisplayString(_ctx.toShortValue(chip)), 3)
          ], 10, _hoisted_4))
        }), 128))
      ]),
      _createVNode(_component_icon_arrow_g, {
        class: "icon-right",
        onClick: _withModifiers(_ctx.moveNext, ["prevent"])
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_ctrl_btn_click_db_click, {
      onSingleClick: _ctx.customClicked,
      onDoubleClick: _ctx.customDbClicked
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.currentChip === 22)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.editMode)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  ref: "input",
                  type: "number",
                  onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCapchaKeydown && _ctx.onCapchaKeydown(...args))),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
                  maxlength: "2"
                }, null, 544)), [
                  [
                    _vModelText,
                    _ctx.inputValue,
                    void 0,
                    { number: true }
                  ],
                  [_directive_click_outside, _ctx.exitEditMode]
                ])
              : _createCommentVNode("", true),
            (!_ctx.editMode && _ctx.customChip <= 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.t('game.customchip')), 1))
              : _createCommentVNode("", true),
            (!_ctx.editMode && _ctx.customChip > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.customChip), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["onSingleClick", "onDoubleClick"])
  ]))
}