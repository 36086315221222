
import { giveFeeVerify } from '@/api'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    setup() {
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()

        const concentInfo = ref<{
            feeAmount: number
            tableId: number
            title: string
            content: string
            tableNo: string
            memberId: number
            status: number
        }>()

        const isMobile = computed((): boolean => store.getters['isMobile'])

        //#region  Vue Helpers

        onBeforeMount(() => {
            emitter.on(EventBuss.TIP_CONCENT_DATA, handleData)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.TIP_CONCENT_DATA, handleData)
            concentInfo.value = undefined
        })

        //#endregion

        const handleData = (arg: {
            feeAmount: number
            tableId: number
            title: string
            content: string
            tableNo: string
            memberId: number
            status: number
        }) => {
            concentInfo.value = arg
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'tipbox-concent'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            if (concentInfo.value) {
                const _params = {
                    memberId: concentInfo.value.memberId,
                    tableId: concentInfo.value.tableId,
                    status: concentInfo.value.status,
                    verifyStatus: 1,
                    feeAmount: concentInfo.value.feeAmount,
                    token: token.value
                }

                store.commit(AppMutation.SHOW_LOADING)
                giveFeeVerify(_params)
                    .then((response) => {
                        store.commit(AppMutation.HIDE_LOADING)
                        close()
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            response.data.data.content
                        )

                        if (response.data.status === 200) {
                            store.commit(
                                AppMutation.SET_COIN,
                                response.data.data.amount
                            )
                        }
                    })
                    .catch(() => {
                        store.commit(AppMutation.HIDE_LOADING)
                        close()
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            '当前网络不佳，请重新尝试'
                        )
                    })
            } else {
                close()
            }
        }
        const cancel = () => {
            if (concentInfo.value) {
                const _params = {
                    memberId: concentInfo.value.memberId,
                    tableId: concentInfo.value.tableId,
                    status: concentInfo.value.status,
                    verifyStatus: -1,
                    feeAmount: concentInfo.value.feeAmount,
                    token: token.value
                }

                store.commit(AppMutation.SHOW_LOADING)
                giveFeeVerify(_params)
                    .then((response) => {
                        store.commit(AppMutation.HIDE_LOADING)
                        close()
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            response.data.data.content
                        )
                    })
                    .catch(() => {
                        store.commit(AppMutation.HIDE_LOADING)
                        close()
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            '当前网络不佳，请重新尝试'
                        )
                    })
            } else {
                close()
            }
        }

        const token = computed(() => store.getters['token'])
        const message = computed(() => {
            if (concentInfo.value) return concentInfo.value.content
            return ''
        })

        const title = computed(() => {
            if (concentInfo.value) return concentInfo.value.title
            return '小费'
        })

        const activeLang = computed((): string => store.getters['lang'])

        return {
            isMobile,
            activeLang,
            message,
            title,
            confirm,
            close,
            cancel,
            t
        }
    }
})
